import * as React from "react";
import { ScrollMenu as RHScrollMenu } from "react-horizontal-scrolling-menu";

interface OwnProps {
  children: any;
}

export const ScrollMenu: React.FunctionComponent<OwnProps> = ({ children }) => {
  return <RHScrollMenu>{children}</RHScrollMenu>;
};
