import { History } from "history";

// 'history' by itself is interpreted as window.history and typscript complains
// that push in undefined.
// Defining the argument as options allows us to use options.history instead
export function signInURL() {
  return "/sign-in";
}

export function goToSignIn(options: { history: History }) {
  return options.history.push(signInURL());
}

export function authenticateURL(options: { redirectTo?: string }) {
  const { redirectTo } = options;
  return `/authenticate${redirectTo ? `?redirectTo=${redirectTo}` : ""}`;
}

export function goToUpgradeAccount(options: { history: History }) {
  return options.history.push("/upgrade-account");
}

export function setUsernameURL() {
  return "/set-username";
}

export function myProfilePageURL() {
  return "/my-profile";
}

export function goToMyProfilePage(options: { history: History }) {
  return options.history.push(myProfilePageURL());
}

export function goToHomePage(options: {
  history: History;
  tab?: string;
  searchString?: string;
}) {
  return options.history.push(
    `${homePageURL(options.tab)}${options.searchString ?? ""}`
  );
}

export function homePageURL(tab?: string) {
  return `/home${tab ? `?tab=${tab}` : ""}`;
}

export function goToUpdatesPage(options: { history: History }) {
  return options.history.push("/updates");
}

export function goToCreateHomePage(options: { history: History }) {
  return options.history.push(createHomePageURL({}));
}

export function createHomePageURL(options: {}) {
  return `/home?tab=MyStories`;
}

export function goToDiscoverHomePage(options: { history: History }) {
  return options.history.push(discoverHomePageURL());
}

export function discoverHomePageURL() {
  return `/home?tab=All`;
}

export function goToPlayHomePage(options: { history: History }) {
  return options.history.push(playHomePageURL({}));
}

export function playHomePageURL(options: {}) {
  return `/home?tab=MyCharacters`;
}

export function goToPlayPage(options: { history: History; storyId: string }) {
  return options.history.push(playPageURL(options));
}

export function playPageURL(options: { storyId: string }) {
  return `/play/${options.storyId}`;
}

export function goToGamePage(options: {
  history: History;
  storyId: string;
  characterId: string;
  isTest?: boolean;
}) {
  return options.history.push(gamePageURL(options));
}

export function gamePageURL(options: {
  storyId: string;
  characterId: string;
  isTest?: boolean;
}) {
  return `/play/${options.storyId}/${options.characterId}${
    options.isTest ? "?isTest=true" : ""
  }`;
}

export function goToRewindPage(options: {
  history: History;
  storyId: string;
  characterId: string;
}) {
  return options.history.push(rewindPageURL(options));
}

export function rewindPageURL(options: {
  storyId: string;
  characterId: string;
}) {
  return `/play/${options.storyId}/${options.characterId}/rewind`;
}

export type editItemType = "message" | "location" | "test" | "tree";

export function goToEditStoryPage(options: {
  history: History;
  storyId: string;
  item_type?: editItemType;
  item_id?: string;
}) {
  return options.history.push(editStoryPageURL(options));
}

export function editStoryPageURL(options: {
  storyId: string;
  item_type?: editItemType;
  item_id?: string;
}) {
  let url = `/story/edit/${options.storyId}`;
  if (options.item_type) {
    url += `/${options.item_type}`;
  }
  if (options.item_id) {
    url += `/${options.item_id}`;
  }
  return url;
}
