/// <reference path="../../../../meso/types.client.ts">
import * as React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
const relativeDay = dayjs.extend(relativeTime);
import AspectRatio from "react-aspect-ratio";
import "react-aspect-ratio/aspect-ratio.css";

import { Box, BoxProps, Button, Flex, Heading, Text } from "@chakra-ui/react";

import { Container } from "../atoms/container";

interface OwnProps extends BoxProps {
  story: StoryClientProps;
  onPlay?: ((story: StoryClientProps) => void) | null;
  sx: any;
  foregroundColor?: string;
  backgroundColor?: string;
}

export const VerticalStoryCard: React.FunctionComponent<OwnProps> = ({
  story,
  onPlay,
  children,
  foregroundColor,
  backgroundColor,
  sx = {},
  ...rest
}) => {
  const bg = backgroundColor || story.backgroundColor || "#FFF";
  const fg = foregroundColor || story.foregroundColor || "#000";

  return (
    <Box
      {...rest}
      textAlign="left"
      sx={{
        minHeight: "400px",
        w: "300px",
        p: "20px",
        border: "1px solid #EBE9F1",
        borderRadius: "10",
        ...sx
      }}
      display="flex"
      flexDirection="column"
      bg="white"
    >
      {story.imageUrls?.small && (
        <Box>
          <AspectRatio ratio="2">
            <img src={`${story.imageUrls.small}`} />
          </AspectRatio>
        </Box>
      )}
      <Heading>{story.name}</Heading>
      {story?.owner?.username && <Text>by {story.owner.username}</Text>}
      {story?.description && (
        <Text
          flex={1}
          sx={{ fontSize: "paragraph_small" }}
          mb={1}
        >{`${story?.description?.slice(0, 150)}${
          story?.description?.length > 150 ? "..." : ""
        }`}</Text>
      )}
      {onPlay && story.published && (
        <Button
          colorScheme="purple"
          rounded="full"
          sx={{ mb: 2 }}
          onClick={() => onPlay(story)}
        >
          Play Now!
        </Button>
      )}
    </Box>
  );
};
