import * as React from "react";

import { useQuery } from "urql";
import { Box, BoxProps, Button, Flex, Heading, Text } from "@chakra-ui/react";

import { VALIDATORS } from "libTools/validators";
import { VerticalStoryCard } from "../molecules/vertical-story-card";
import { playPageURL } from "fargie/js/utils/navigation-utils";
import { ScrollMenu } from "../molecules/scroll-menu";

const MOST_POPULAR = `
  query PopularStories {
    popularStories {
      name
      _id
      description
      published
      imageUrls {
        small
      }
      owner {
        username
        _id
      }
    }
  }
`;

export const PopularStories = ({}) => {
  const [result, reexecuteQuery] = useQuery({
    query: MOST_POPULAR
  });

  const { data, fetching, error } = result;

  if (fetching) return <p>Loading...</p>;
  if (error) return <p>Oh no... {error.message}</p>;

  const { popularStories } = data;

  return (
    <Box color="black" bg="#F8F8F8" textAlign="center" p="5">
      <ScrollMenu>
        {popularStories?.map(story => {
          const decodedStory = VALIDATORS.story.decode(story);
          return (
            <VerticalStoryCard
              sx={{ mr: 8, mb: 4 }}
              story={decodedStory}
              onPlay={() => {
                window.location.href = playPageURL({
                  storyId: decodedStory._id
                });
              }}
              key={story.name}
            />
          );
        })}
      </ScrollMenu>
    </Box>
  );
};
