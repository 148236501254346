import * as _ from "lodash";
import he from "he";
import validator from "validator";

import { deltaToText } from "argie-quill-mod/server-side";

const COMMAND = {
  LENGTHS: {
    NAME: {
      MIN: 3,
      MAX: 20,
    },
    TEXT: {
      MIN: 3,
      MAX: 50,
    },
  },
  MATCHERS: {
    NAME: /^[A-Z0-9-_]*$/,
    TEXT: /^[^<>'"`}{]*$/, // No HTML chars or curly braces
  },
};

export const LENGTHS = {
  ACCOUNT: {
    USERNAME: {
      MIN: 3,
      MAX: 20,
    },
  },
  STORY: {
    NAME: {
      MIN: 3,
      MAX: 50,
    },
    DESCRIPTION: {
      MIN: 0,
      MAX: 1500,
    },
  },
  FIELD_TYPE: {
    MIN: 3,
    MAX: 20,
  },
  GLOBAL: {
    MIN: 3,
    MAX: 20,
  },
  GLOBAL_TYPE: {
    MIN: 3,
    MAX: 20,
  },
  LOCATION: {
    NAME: COMMAND.LENGTHS.NAME,
    TITLE: {
      MIN: 3,
      MAX: 50,
    },
    EXIT: {
      NAME: COMMAND.LENGTHS.NAME,
      TITLE: COMMAND.LENGTHS.TEXT,
    },
    COMMAND: {
      NAME: COMMAND.LENGTHS.NAME,
      TEXT: COMMAND.LENGTHS.TEXT,
    },
  },
  MESSAGE: {
    NAME: COMMAND.LENGTHS.NAME,
    TITLE: {
      MIN: 3,
      MAX: 50,
    },
  },
};

export const MATCHERS = {
  ACCOUNT: {
    USERNAME: /^[0-9a-zA-z_-]*$/,
  },
  STORY: {
    NAME: /^[0-9A-Za-z-_ ]*$/,
    DESCRIPTION: /^[^<>'"`]*$/, // No HTML chars
  },
  FIELD: {
    NAME: /^[0-9a-zA-z-_ ]*$/,
    TYPE: /^[0-9a-zA-Z-_ ]*$/,
  },
  GLOBAL: {
    NAME: /^[0-9a-zA-z_-]*$/,
    TYPE: /^[0-9a-zA-Z-_ ]*$/,
  },
  LOCATION: {
    NAME: COMMAND.MATCHERS.NAME,
    TITLE: /^[0-9A-Za-z-_ ]*$/,
    EXIT: {
      NAME: COMMAND.MATCHERS.NAME,
      TITLE: COMMAND.MATCHERS.TEXT,
    },
    COMMAND: {
      NAME: COMMAND.MATCHERS.NAME,
      TEXT: COMMAND.MATCHERS.TEXT,
    },
  },
  MESSAGE: {
    NAME: COMMAND.MATCHERS.NAME,
    TITLE: /^[0-9A-Za-z-_ ]*$/,
  },
};

function safeValidate<T>(func: (any: T) => T, arg: T) {
  let error = undefined;
  let newArg: undefined | T = undefined;
  try {
    newArg = func(arg);
  } catch (e) {
    error = e.toString().replace("Error: ", "");
  }
  return [newArg, error];
}

export const VALIDATORS = {
  account: {
    decode(account: any) {
      if (account?.username) {
        return {
          ...account,
          username: he.decode(account.username),
        };
      }
      return account;
    },
    username(username: string, encode: boolean = false) {
      if (username === undefined || username === null) {
        return name;
      }
      if (username.length < LENGTHS.ACCOUNT.USERNAME.MIN) {
        throw new Error(
          `Username must be at least ${LENGTHS.ACCOUNT.USERNAME.MIN} characters.`
        );
      }
      if (username.length > LENGTHS.ACCOUNT.USERNAME.MAX) {
        throw new Error(
          `Username may not be more than ${LENGTHS.ACCOUNT.USERNAME.MAX} characters.`
        );
      }
      if (!username.match(MATCHERS.ACCOUNT.USERNAME)) {
        throw new Error(
          "Username may only contain letters, numbers, or dashes"
        );
      }

      // let safeUsername = username;
      // if (encode) {
      // safeUsername = he.encode(username);
      // if (!safeUsername.match(MATCHERS.ACCOUNT.USERNAME)) {
      // throw new Error("Username has illegal characters.");
      // }
      // }
      return validator.stripLow(username);
    },
  },
  story: {
    decode(story: any) {
      if (story.description) {
        return {
          ...story,
          description: he.decode(story.description),
        };
      }
      return story;
    },
    story(
      story: any,
      encode: boolean = false
    ): [any, { [key: string]: string }] {
      const safeStory: any = {};
      const errors: any = {};
      if (story.name) {
        try {
          safeStory.name = VALIDATORS.story.name(story.name);
        } catch (e) {
          errors.name = e.toString().replace("Error: ", "");
        }
      }
      if (story.description) {
        try {
          safeStory.description = VALIDATORS.story.description(
            story.description,
            encode
          );
        } catch (e) {
          errors.description = e.toString().replace("Error: ", "");
        }
      }
      if (story.startFrom) {
        try {
          safeStory.startFrom = VALIDATORS.story.startFrom(story.startFrom);
        } catch (e) {
          errors.startFrom = e.toString().replace("Error: ", "");
        }
      }
      if (story.startName) {
        try {
          safeStory.startName = VALIDATORS.location.name(story.startName);
        } catch (e) {
          errors.startName = e.toString().replace("Error: ", "");
        }
      }
      if (story.namespaceDelimiter || story.namespaceDelimiter === "") {
        try {
          safeStory.namespaceDelimiter = VALIDATORS.story.namespaceDelimiter(
            story.namespaceDelimiter
          );
        } catch (e) {
          errors.namespaceDelimiter = e.toString().replace("Error: ", "");
        }
      }
      if (!_.isNil(story.hasPronounsField)) {
        try {
          if (!validator.isBoolean(`${story.hasPronounsField}`)) {
            throw new Error("hasPronounsField must be a boolean");
          }
          safeStory.hasPronounsField = story.hasPronounsField;
        } catch (e) {
          errors.hasPronounsField = e.toString().replace("Error: ", "");
        }
      }
      if (!_.isNil(story.hasAdultContent)) {
        try {
          if (!validator.isBoolean(`${story.hasAdultContent}`)) {
            throw new Error("hasAdultContent must be a boolean");
          }
          safeStory.hasAdultContent = story.hasAdultContent;
        } catch (e) {
          errors.hasAdultContent = e.toString().replace("Error: ", "");
        }
      }
      if (story.globals) {
        try {
          safeStory.globals = VALIDATORS.story.globals(story.globals);
        } catch (e) {
          errors.globals = e.toString().replace("Error: ", "");
        }
      }
      if (story.fields) {
        try {
          safeStory.fields = VALIDATORS.story.fields(story.fields);
        } catch (e) {
          errors.fields = e.toString().replace("Error: ", "");
        }
      }
      if (story.foregroundColor) {
        try {
          safeStory.foregroundColor = VALIDATORS.story.foregroundColor(
            story.foregroundColor
          );
        } catch (e) {
          errors.foregroundColor = e;
        }
      }
      if (story.backgroundColor) {
        try {
          safeStory.backgroundColor = VALIDATORS.story.backgroundColor(
            story.backgroundColor
          );
        } catch (e) {
          errors.backgroundColor = e;
        }
      }
      if (story.imageUrls) {
        safeStory.imageUrls = {};
        try {
          for (const size in story.imageUrls) {
            if (story.imageUrls[size]) {
              if (!validator.isURL(story.imageUrls[size])) {
                throw `image URL ${size} is not a real URL`;
              }
            }
            safeStory.imageUrls[size] = story.imageUrls[size];
          }
        } catch (e) {
          errors.imageUrls = e;
        }
      }
      if (story.transition) {
        if (
          story.transition === "fade" ||
          story.transition === "replace" ||
          story.transition === "print-out"
        ) {
          safeStory.transition = story.transition;
        }
      }
      if (story.optionsAppearance) {
        if (
          story.optionsAppearance === "buttons" ||
          story.optionsAppearance === "rows"
        ) {
          safeStory.optionsAppearance = story.optionsAppearance;
        }
      }

      return [safeStory, errors];
    },

    name(name?: string) {
      if (name === undefined || name === null) {
        return name;
      }
      if (name.length < LENGTHS.STORY.NAME.MIN) {
        throw new Error(
          `Story name must be at least ${LENGTHS.STORY.NAME.MIN} characters.`
        );
      }
      if (name.length > LENGTHS.STORY.NAME.MAX) {
        throw new Error(
          `Story name may not be more than ${LENGTHS.STORY.NAME.MAX} characters.`
        );
      }
      if (!name.match(MATCHERS.STORY.NAME) || !validator.isAscii(name)) {
        throw new Error(
          "Story name may only contain letters, numbers, dashes, or spaces."
        );
      }
      return validator.stripLow(name);
    },

    description(desc?: string, encode: boolean = false) {
      if (desc === null || desc === undefined || desc === "") {
        return desc;
      }
      if (desc.length < LENGTHS.STORY.DESCRIPTION.MIN) {
        throw new Error(
          `Description must be at least ${LENGTHS.STORY.DESCRIPTION.MIN} characters.`
        );
      }
      if (desc.length > LENGTHS.STORY.DESCRIPTION.MAX) {
        throw new Error(
          `Description may not be more than ${LENGTHS.STORY.DESCRIPTION.MAX} characters.`
        );
      }
      let safeDesc = desc;
      if (encode) {
        safeDesc = he.encode(desc);
        if (!safeDesc.match(MATCHERS.STORY.DESCRIPTION)) {
          throw new Error("Description has illegal characters.");
        }
      }
      return validator.stripLow(safeDesc);
    },

    startFrom(startFrom?: string) {
      if (startFrom === null || startFrom === undefined) {
        return startFrom;
      }
      if (startFrom !== "location" && startFrom !== "message") {
        throw new Error("StartFrom must be either location or message");
      }
      return validator.stripLow(startFrom);
    },

    namespaceDelimiter(nsd) {
      if (nsd === null || nsd === undefined) {
        return nsd;
      }
      if (nsd !== "" && nsd !== "-" && nsd !== "_") {
        throw new Error("Namespace delimiter must be either - or _");
      }
      return validator.stripLow(nsd);
    },

    foregroundColor(color?: string) {
      if (color === null || color === undefined) {
        return color;
      }
      if (!validator.isHexColor(color)) {
        throw new Error(`Foreground color ${color} is not a valid color`);
      }
      return validator.stripLow(color);
    },

    backgroundColor(color?: string) {
      if (color === null || color === undefined) {
        return color;
      }
      if (!validator.isHexColor(color)) {
        throw new Error(`Background color ${color} is not a valid color`);
      }
      return validator.stripLow(color);
    },

    globals(globals) {
      if (globals === null || globals === undefined) {
        return globals;
      }
      for (const global of globals) {
        VALIDATORS.story.global(global);
      }
      return globals;
    },

    global(global: { name: string; type: string[] }) {
      if (!global.name) {
        throw new Error("Global name is missing");
      }

      if (!global.type || !global.type.length) {
        throw new Error("Global type is missing");
      }
      if (global.type.length < 1) {
        throw new Error("Global must have at least 1 type option");
      }
      if (global.type.length > 10) {
        throw new Error("Globals may not have more than 10 type options");
      }
      if (!global.name.match(MATCHERS.GLOBAL.NAME)) {
        throw new Error(
          "Global name may only contain letters, numbers, or dashes"
        );
      }
      const safeTypes: string[] = [];
      for (const option of global.type) {
        if (option.length < LENGTHS.GLOBAL_TYPE.MIN) {
          throw new Error(
            `Global options must be at least ${LENGTHS.GLOBAL_TYPE.MIN} characters`
          );
        }
        if (option.length > LENGTHS.GLOBAL_TYPE.MAX) {
          throw new Error(
            `Global options may not be more than ${LENGTHS.GLOBAL_TYPE.MAX} characters`
          );
        }
        if (!option.match(MATCHERS.GLOBAL.TYPE)) {
          throw new Error(
            "Global options may only contain letters, numbers, spaces, or dashes"
          );
        }
        safeTypes.push(validator.stripLow(option));
      }
      return {
        name: validator.stripLow(global.name),
        type: safeTypes,
      };
    },

    fields(fields) {
      if (fields === null || fields === undefined) {
        return fields;
      }
      for (const field of fields) {
        this.field.field(field);
      }
      return fields;
    },

    field: {
      field(field: { name: string; type: string[] }) {
        this.name(field.name);
        this.types(field.type);
        return field;
      },

      name(name: string) {
        if (!name) {
          throw new Error("Field name is missing");
        }
        if (!name.match(MATCHERS.FIELD.NAME)) {
          throw new Error(
            "Field name may only contain letters, numbers, or dashes"
          );
        }
        return validator.stripLow(name);
      },

      types(type: string[]) {
        if (!type || !type.length) {
          throw new Error("Field type is missing");
        }
        if (type.length < 1) {
          throw new Error("Field must have at least 1 type option");
        }
        if (type.length > 10) {
          throw new Error("Fields may not have more than 10 type options");
        }
        const safeTypes: string[] = [];
        for (const option of type) {
          if (option.length < LENGTHS.FIELD_TYPE.MIN) {
            throw new Error(
              `Field options must be at least ${LENGTHS.GLOBAL_TYPE.MIN} characters`
            );
          }
          if (option.length > LENGTHS.FIELD_TYPE.MAX) {
            throw new Error(
              `Field options may not be more than ${LENGTHS.GLOBAL_TYPE.MAX} characters`
            );
          }
          if (!option.match(MATCHERS.FIELD.TYPE)) {
            throw new Error(
              "Field options may only contain letters, numbers, spaces, or dashes"
            );
          }
          safeTypes.push(validator.stripLow(option));
        }
        return safeTypes;
      },
    },
  },

  location: {
    decode(location?: any) {
      return {
        ...location,
        exits: location?.exits?.map(exit => this.exit.decode(exit)),
        commands: location?.commands?.map(command =>
          this.command.decode(command)
        ),
      };
    },
    location(
      location: any,
      encode: boolean = false
    ): [any, { [key: string]: string }] {
      const safeLocation: any = {};
      const errors: any = {};
      if (location.name || location.name === "") {
        try {
          safeLocation.name = VALIDATORS.location.name(location.name);
        } catch (e) {
          errors.name = e.toString().replace("Error: ", "");
        }
      }
      if (location.title || location.title === "") {
        try {
          safeLocation.title = VALIDATORS.location.title(location.title);
        } catch (e) {
          errors.title = e.toString().replace("Error: ", "");
        }
      }
      if (location.ops) {
        safeLocation.ops = location.ops;
      }
      if (location.postRunTriggerOps) {
        safeLocation.postRunTriggerOps = location.postRunTriggerOps;
      }
      if (location.exits) {
        try {
          safeLocation.exits = VALIDATORS.location.exits(
            location.exits,
            encode
          );
        } catch (e) {
          errors.locations = e.toString().replace("Error: ", "");
        }
      }
      if (location.commands) {
        try {
          safeLocation.commands = VALIDATORS.location.commands(
            location.commands,
            encode
          );
        } catch (e) {
          errors.commands = e.toString().replace("Error: ", "");
        }
      }
      return [safeLocation, errors];
    },
    name: (originalName?: string) => {
      const name = originalName?.toUpperCase();
      if (name === undefined || name === null) {
        return name;
      }
      if (name.length < LENGTHS.LOCATION.NAME.MIN) {
        throw new Error(
          `Location id must be at least ${LENGTHS.LOCATION.NAME.MIN} characters.`
        );
      }
      if (name.length > LENGTHS.LOCATION.NAME.MAX) {
        throw new Error(
          `Location id may not be more than ${LENGTHS.LOCATION.NAME.MAX} characters.`
        );
      }
      if (!name.match(MATCHERS.LOCATION.NAME) || !validator.isAscii(name)) {
        throw new Error(
          "Location id may only contain uppercase letters, numbers, or dashes."
        );
      }
      return validator.stripLow(name);
    },
    title: (title?: string) => {
      if (title === undefined || title === null) {
        return title;
      }
      if (title.length < LENGTHS.LOCATION.TITLE.MIN) {
        throw new Error(
          `Location title must be at least ${LENGTHS.LOCATION.TITLE.MIN} characters.`
        );
      }
      if (title.length > LENGTHS.LOCATION.TITLE.MAX) {
        throw new Error(
          `Location title may not be more than ${LENGTHS.LOCATION.TITLE.MAX} characters.`
        );
      }
      if (!title.match(MATCHERS.LOCATION.TITLE) || !validator.isAscii(title)) {
        throw new Error(
          "Location title may only contain uppercase letters, numbers, or dashes."
        );
      }
      return validator.stripLow(title);
    },

    exits(exits: any, encode: boolean = false) {
      if (exits === null || exits === undefined) {
        return exits;
      }
      const safeExits = [] as { locationName; title; visibleByDefault }[];
      for (const exit of exits) {
        safeExits.push(VALIDATORS.location.exit.exit(exit, encode));
      }
      return safeExits;
    },
    exit: {
      decode(exit) {
        return { ...exit, title: he.decode(exit.title) };
      },
      exit(
        exit: {
          locationName: string;
          title: string;
          visibleByDefault?: boolean;
        },
        encode = false
      ) {
        const safeExit = {} as any;
        safeExit.locationName = this.locationName(exit.locationName);
        safeExit.title = this.title(exit.title, encode);
        safeExit.visibleByDefault = exit.visibleByDefault;
        return safeExit;
      },

      locationName(locationName: string) {
        if (!locationName) {
          throw new Error("Exit location name is required.");
        }
        if (locationName.length < LENGTHS.LOCATION.EXIT.NAME.MIN) {
          throw new Error(
            `Exit location name must be at least ${LENGTHS.LOCATION.EXIT.NAME.MIN} characters.`
          );
        }
        if (locationName.length > LENGTHS.LOCATION.EXIT.NAME.MAX) {
          throw new Error(
            `Exit location name may not be more than ${LENGTHS.LOCATION.EXIT.NAME.MAX} characters.`
          );
        }
        const safeName = validator.stripLow(locationName.toUpperCase());
        if (
          !safeName.match(MATCHERS.LOCATION.EXIT.NAME) ||
          !validator.isAscii(safeName)
        ) {
          throw new Error(
            "Exit location name may only contain letters, numbers, dashes, or spaces."
          );
        }
        return safeName;
      },
      title(title: string, encode: boolean = false) {
        if (!title) {
          throw new Error("Exit title is required.");
        }
        if (title.length < LENGTHS.LOCATION.EXIT.TITLE.MIN) {
          throw new Error(
            `Exit title must be at least ${LENGTHS.LOCATION.EXIT.TITLE.MIN} characters.`
          );
        }
        if (title.length > LENGTHS.LOCATION.EXIT.TITLE.MAX) {
          throw new Error(
            `Exit title may not be more than ${LENGTHS.LOCATION.EXIT.TITLE.MAX} characters.`
          );
        }
        let safeTitle = validator.stripLow(title);
        if (encode) {
          safeTitle = he.encode(safeTitle);
          if (
            !safeTitle.match(MATCHERS.LOCATION.EXIT.TITLE) ||
            !validator.isAscii(safeTitle)
          ) {
            throw new Error("Exit title contains an invalid character.");
          }
        }
        return safeTitle;
      },
    },

    commands(commands: any, encode: boolean = false) {
      if (commands === null || commands === undefined) {
        return commands;
      }
      const safeCommands = [] as {
        messageName;
        commandText;
        visibleByDefault;
      }[];
      for (const command of commands) {
        safeCommands.push(VALIDATORS.location.command.command(command, encode));
      }
      return safeCommands;
    },
    command: {
      decode(command) {
        return { ...command, commandText: he.decode(command.commandText) };
      },
      command(
        command: {
          messageName: string;
          commandText: string;
          visibleByDefault?: boolean;
        },
        encode = false
      ) {
        const safeCommand = {} as any;
        safeCommand.messageName = this.messageName(command.messageName);
        safeCommand.commandText = this.commandText(command.commandText, encode);
        safeCommand.visibleByDefault = command.visibleByDefault;
        return safeCommand;
      },

      messageName(messageName: string) {
        if (!messageName) {
          throw new Error("Command message name is required.");
        }
        if (messageName.length < LENGTHS.LOCATION.COMMAND.NAME.MIN) {
          throw new Error(
            `Command message name must be at least ${LENGTHS.LOCATION.COMMAND.NAME.MIN} characters.`
          );
        }
        if (messageName.length > LENGTHS.LOCATION.COMMAND.NAME.MAX) {
          throw new Error(
            `Command message name may not be more than ${LENGTHS.LOCATION.COMMAND.NAME.MAX} characters.`
          );
        }
        const safeName = validator.stripLow(messageName.toUpperCase());
        if (
          !safeName.match(MATCHERS.LOCATION.COMMAND.NAME) ||
          !validator.isAscii(safeName)
        ) {
          throw new Error(
            "Command message name may only contain letters, numbers, dashes, or spaces."
          );
        }
        return safeName;
      },

      commandText(commandText: string, encode: boolean = false) {
        if (!commandText) {
          throw new Error("Command text is required.");
        }
        if (commandText.length < LENGTHS.LOCATION.COMMAND.TEXT.MIN) {
          throw new Error(
            `Command text must be at least ${LENGTHS.LOCATION.COMMAND.TEXT.MIN} characters.`
          );
        }
        if (commandText.length > LENGTHS.LOCATION.COMMAND.TEXT.MAX) {
          throw new Error(
            `Command text may not be more than ${LENGTHS.LOCATION.COMMAND.TEXT.MAX} characters.`
          );
        }
        let safeText = validator.stripLow(commandText);
        if (encode) {
          safeText = he.encode(safeText);
          if (
            !safeText.match(MATCHERS.LOCATION.COMMAND.TEXT) ||
            !validator.isAscii(safeText)
          ) {
            throw new Error(
              "Command text may only contain letters, numbers, dashes, or spaces."
            );
          }
        }
        return safeText;
      },
    },
  },

  message: {
    message(message: any): [any, { [key: string]: string }] {
      const safeMessage: any = {};
      const errors: any = {};
      if (message.name || message.name === "") {
        try {
          safeMessage.name = this.name(message.name);
        } catch (e) {
          errors.name = e.toString().replace("Error: ", "");
        }
      }
      if (message.title) {
        try {
          safeMessage.title = this.title(message.title);
        } catch (e) {
          errors.title = e.toString().replace("Error: ", "");
        }
      }

      if (message.ops) {
        safeMessage.ops = message.ops;
      }
      if (message.postRunTriggerOps) {
        safeMessage.postRunTriggerOps = message.postRunTriggerOps;
      }
      if (!_.isNil(message.isTrigger)) {
        safeMessage.isTrigger = !!message.isTrigger;
      }
      if (!_.isNil(message.preserveCommands)) {
        safeMessage.preserveCommands = !!message.preserveCommands;
      }
      if (message.type) {
        if (message.type === "page" || message.type === "message") {
          safeMessage.type = message.type;
        } else {
          errors.type = "Type must be 'page' or 'message'";
        }
      }
      return [safeMessage, errors];
    },
    name: (originalName?: string) => {
      const name = originalName?.toUpperCase();
      if (name === undefined || name === null) {
        return name;
      }
      if (name.length < LENGTHS.MESSAGE.NAME.MIN) {
        throw new Error(
          `ID must be at least ${LENGTHS.MESSAGE.NAME.MIN} characters.`
        );
      }
      if (name.length > LENGTHS.MESSAGE.NAME.MAX) {
        throw new Error(
          `ID may not be more than ${LENGTHS.MESSAGE.NAME.MAX} characters.`
        );
      }
      if (!name.match(MATCHERS.MESSAGE.NAME) || !validator.isAscii(name)) {
        throw new Error(
          "ID may only contain uppercase letters, numbers, or dashes."
        );
      }
      return validator.stripLow(name);
    },
    title: (title?: string) => {
      if (title === undefined || title === null) {
        return title;
      }
      if (title.length < LENGTHS.LOCATION.TITLE.MIN) {
        throw new Error(
          `Page title must be at least ${LENGTHS.LOCATION.TITLE.MIN} characters.`
        );
      }
      if (title.length > LENGTHS.LOCATION.TITLE.MAX) {
        throw new Error(
          `Page title may not be more than ${LENGTHS.LOCATION.TITLE.MAX} characters.`
        );
      }
      if (!title.match(MATCHERS.LOCATION.TITLE) || !validator.isAscii(title)) {
        throw new Error(
          "Page title may only contain uppercase letters, numbers, or dashes."
        );
      }
      return validator.stripLow(title);
    },
  },
};
